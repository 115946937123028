import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {SignUpRequest} from 'src/app/models/sign-up-request.model';
import {RegisterService} from 'src/app/services/register.service';
import {environment} from 'src/environments/environment';

import countriesArr from 'src/app/assets/data/countries-phone-codes';
import {SchoolTypes} from '../../models/gobalEnum';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    @Input() saveRegister: number;
    form: FormGroup;
    position = 'top-center';
    environment = environment;
    countries = countriesArr;
    schoolTypes = SchoolTypes;
    schoolTypesKeys = Object.keys(SchoolTypes);

    constructor(
        private fb: FormBuilder,
        private registerService: RegisterService,
        private toastyService: ToastyService,
    ) {
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.saveRegister.currentValue > 0) {
            this.registerCustomer();
        }
    }

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm() {
        this.form = this.fb.group({
            names: ['', [Validators.required]],
            lastNames: ['', [Validators.required]],
            email: ['', [Validators.required]],
            password: ['', [Validators.required]],
            passwordConfirm: ['', [Validators.required]],
            schoolName: ['', [Validators.required]],
            schoolType: ['', [Validators.required]],
            quantityUsers: ['', [Validators.required]],
            phone_code: ['+(502)', [Validators.required]],
            contactNumber: ['', [Validators.required]]
        });
    }

    getNormalName(name: string): any {
        const cleaned = name.replace('data.', '');
        switch (cleaned) {
            case 'names': {
                return 'Nombres';
                break;
            }
            case 'schoolName': {
                return 'Nombre de Institucion';
                break;
            }
            case 'password': {
                return 'Contraseña';
                break;
            }
            case 'passwordConfirm': {
                return 'Confirmar contraseña';
                break;
            }
            case 'quantityUsers': {
                return 'Cantidad de alumnos';
                break;
            }
            case 'lastNames': {
                return 'Apellidos';
                break;
            }
            case 'schoolType': {
                return 'Tipo de institución';
                break;
            }
            case 'contactNumber': {
                return 'Número de contacto';
                break;
            }
            case 'CONFIRM_PWD_ERROR' || 'CUSTOMER_ERROR' || 'ERROR_CONTACT_NUMBER': {
                return 'Error';
                break;
            } default: {
              return cleaned;
              break;
          }
        }
    }

    registerCustomer() {
        this.toastyService.clearAll();

        if(this.form.valid) {
            let toastOptions: ToastOptions = {
                title: 'Espere',
                msg: 'Guardando registro',
                timeout: 5000,
                theme: 'default'
            };
            this.toastyService.wait(toastOptions);
            const newCustomer: SignUpRequest = {
                ...this.form.value,
                planId: '3f5f0715-39ab-4f99-8b97-ee25b5ee01db'
            }
            if(newCustomer.contactNumber){
                newCustomer.contactNumber = this.form.value.phone_code + newCustomer.contactNumber;
            }
            this.registerService.registerNewCustomer(newCustomer)
                .subscribe(() => {
                    this.toastyService.clearAll();
                    let toastOptions: ToastOptions = {
                        title: 'Éxito',
                        msg: 'Registro enviado exitosamente.',
                        timeout: 3000,
                        theme: 'default'
                    };
                    this.toastyService.info(toastOptions);
                    this.form.reset();
                }, err => {
                    let errors = '';
                    err?.error?.errors?.map(mappedError => {
                        console.log(mappedError);
                        errors += `${this.getNormalName(mappedError.code)}: ${mappedError.description}. `;
                    });
                    this.toastyService.clearAll();
                    let toastOptions: ToastOptions = {
                        title: 'Error',
                        msg: errors,
                        timeout: 5000,
                        theme: 'default'
                    };
                    this.toastyService.error(toastOptions);
                });
        } else {
            const toastOptions: ToastOptions = {
                title: 'Error',
                msg: 'Completar todos los campos obligatorios!',
                timeout: 3000,
                theme: 'default'
            };
            this.toastyService.error(toastOptions);
        }
    }

}
