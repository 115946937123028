import {Component, OnInit} from '@angular/core';
import {menuOption} from '../models/gobalEnum';

@Component({
  selector: 'app-edukcare',
  templateUrl: './edukcare.component.html',
  styleUrls: ['./edukcare.component.scss']
})
export class EdukcareComponent implements OnInit {
  constructor() { }
  openRegister = false;
  optionMenu: menuOption;
  register = 0;

  saveRegister(): void{
    this.register = this.register + 1;
  }
  clickMenu(opt: menuOption): void{
    this.optionMenu = opt;
  }

  ngOnInit(): void {
    this.optionMenu = menuOption.customer;
  }

}
